<template>
  <div class="myVehicle">
    <div class="nav">
      <div class="nav_title">我的钱包</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="wallet_detail">
      <ul class="detail_list">
        <li>现金余额</li>
        <li>
          <van-icon name="balance-o" />
          <p>￥{{ Number(infoData.balance / 100).toFixed(2) }}</p>
        </li>
      </ul>
      <ul class="detail_list">
        <li>补助金额</li>
        <li>
          <van-icon name="after-sale" />
          <p>￥{{ Number(infoData.allowance / 100).toFixed(2) }}</p>
        </li>
      </ul>
      <ul class="detail_list">
        <li>优惠余额</li>
        <li>
          <van-icon name="gold-coin" />
          <p>￥{{ Number(infoData.coupon / 100).toFixed(2) }}</p>
        </li>
      </ul>
      <!-- <ul class="detail_list">
        <li @click="toPage('MemberCard')">我的会员卡</li>
        <li>
          <van-icon name="vip-card" />
          <p>1张</p>
        </li>
      </ul>-->
      <ul class="detail_list">
        <li @click="toPage('Consumption')">
          <p>我的消费记录</p>
          <p>
            <img style="width:2rem;height:1.5rem" :src="right" alt />
          </p>
        </li>
      </ul>
    </div>
    <div v-if="!isWeixin && isShow" style="width: 100%;box-sizing: border-box;padding: 0px 20px;position: absolute;bottom: 20px;">
      <van-button style="width: 100%;background: #ffa900;font-size: 16px;color: #fff;" @click="toPage('cash-withdrawal')">提现</van-button>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
// import vip from "@/assets/vip.png";
import right from "@/assets/right.png";
import { getUserSystemSetupAPI } from '@/api/family.js'

export default {
  data() {
    return {
      cancel,
      // vip,
      right,
      infoData: {},
      isWeixin: true,
      isShow: false
    };
  },
  created() {
    this.getInfoList();
    this.getUserSystemSetupFn();
    const user = JSON.parse(sessionStorage.getItem('personInfo'));
    if (user.organizationId === 69 || user.organizationId === 76) {
      this.isWeixin = true;
    }else {
      this.isWeixin = false;
    }
    
  },
  methods: {
    toBack() {
      this.$router.back();
    },
    toPage(name) {
      this.$router.push(name);
    },
    // 获取本地存储中的个人信息
    getInfoList() {
      this.$store
        .dispatch("user/getInfo")
        .then((data) => {
          this.infoData = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getUserSystemSetupFn() {
      const organizationId = JSON.parse(sessionStorage.getItem("personInfo")).organizationId;
      const user = JSON.parse(sessionStorage.getItem("personInfo"));
      const res = await getUserSystemSetupAPI(organizationId);
      if (res.code === 0 && res.data !== null) {
        if (JSON.parse(res.data.setting) !== "{}") {
          if (JSON.parse(res.data.setting).refund_set.show) {
            this.isShow = true;
          }else {
            this.isShow = false;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-field__body {
  display: block;
  box-sizing: border-box;
  padding: 0 5px;
  height: 2.5rem;
  line-height: 2.5rem;
  border: 1px solid rgba(181, 201, 210, 0.6);
}
.myVehicle {
  height: 100%;
  display: flex;
  flex-direction: column;
  //   background: #fff;
  background: rgba(241, 241, 241);
  position: relative;
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .wallet_detail {
    margin: 3rem 0.5rem 0;
    .detail_list {
      background: #ffffff;
      border-radius: 0.75rem;
      li:nth-child(1) {
        height: 2.5rem;
        line-height: 2.5rem;
        width: 95%;
        margin: 0.5rem auto;
        border-bottom: 1px solid #cccccc;
        text-indent: 0.3rem;
      }
      li:nth-child(2) {
        padding: 0.5rem 0;
        display: flex;
        align-items: center;
        width: 95%;
        margin: auto;
        .van-icon {
          font-size: 2rem;
          margin-right: 0.5rem;
        }
        p:first-child {
          width: 10%;
          margin-right: 0.5rem;
          img {
            width: 100%;
          }
        }
      }
    }
    .detail_list:last-child {
      li {
        display: flex;
        justify-content: space-between;
        border: none;
        p {
          margin: 0;
          display: flex;
          align-items: center;
        }
        p:last-child {
          margin-right: 1rem;
        }
      }
    }
  }
}
::v-deep .van-button__text{
  font-weight: bold;
}
</style>